<template>
  <div>
    <b-overlay
      :show="load"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Gerar Campanha"
        back-button-text="Voltar"
        class="mb-3"
        @on-complete="formSubmitted"
      >

        <!-- accoint details tab -->
        <tab-content
          title="Base"
          :before-change="validationForm"
        >
          <validation-observer
            ref="accountRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Selecione por qual base deseja realizar os filtros
                </h5>
              </b-col>
              <b-col md="4">
                <!-- button -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  :variant="variantBase('operacao')"
                  @click="toggleIndeterminate('operacao')"
                >
                  Operações
                </b-button>
              </b-col>
              <b-col md="4">

                <!-- button -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  :variant="variantBase('parcela')"
                  @click="toggleIndeterminate('parcela')"
                >
                  Parcelas
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- personal details tab -->
        <tab-content
          title="Filtros"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <Operacoes />
          </validation-observer>
        </tab-content>
        <tab-content
          title="Totais"
          :before-change="validationFormSocial"
        >
          <validation-observer
            ref="socialRules"
            tag="form"
          >
            <b-row>
              <b-col
                v-if="showTotais"
                cols="12"
                class="mb-2"
              >
                <totais
                  :data="totais"
                />
              </b-col>
              <div
                v-else
                class="d-flex align-items-center"
              >
                <strong>Aguarde...</strong>
                <b-spinner class="ml-auto" />
              </div>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- address  -->
        <tab-content
          title="Tipo"
          :before-change="validationFormAddress"
        >
          <validation-observer
            ref="addressRules"
            tag="form"
          >
            <b-row>
              <b-col
                md="2"
                hidden
              >
                <label>Respiro</label>
                <b-form-input
                  v-model="respiro"
                  type="number"
                  size="sm"
                  placeholder="0"
                />
              </b-col>
              <b-col cols="3">
                <label>Data de Início</label>
                <b-form-input
                  id="data-inicio"
                  v-model="campanhaDataInicio"
                  size="sm"
                  locale="pt"
                  type="date"
                />
              </b-col>
              <b-col md="2">
                <div>
                  <label for="hora-inicio">Horário Inícial</label>
                  <b-form-input
                    id="hora-inicio"
                    v-model="horaInicio"
                    type="time"
                    size="sm"
                    placeholder="Inicio"
                    local="en"
                  />
                </div>
              </b-col>
              <b-col
                cols="3"
              >
                <label>Data Final</label>
                <b-form-input
                  id="vencimento-final"
                  v-model="campanhaDataFinal"
                  size="sm"
                  locale="pt"
                  type="date"
                />
              </b-col>
              <b-col
                md="2"
              >
                <div>
                  <label for="hora-final">Horário Final</label>
                  <b-form-input
                    id="hora-final"
                    v-model="horaFinal"
                    type="time"
                    size="sm"
                    placeholder="Final"
                    local="en"
                  />
                </div>
              </b-col>
              <b-col
                v-show="tipo === 'whatsapp'"
                class="mt-1"
                md="4"
              >
                <label>Números por Envio</label>
                <b-form-input
                  v-model="numerosPorDisparo"
                  type="number"
                  size="sm"
                  placeholder="0"
                />
              </b-col>
              <b-col
                v-show="tipo === 'whatsapp'"
                class="mt-1"
                md="4"
              >
                <label>Intervalo de Envio</label>
                <b-form-group>
                  <b-form-input
                    v-model="intervaloEnvio"
                    type="number"
                    size="sm"
                    placeholder="Ex: 10"
                  />
                  <b-form-invalid-feedback :state="validation">
                    O intervalo de envio tem que ser no minimo 10
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                v-show="tipo === 'whatsapp'"
                class="mt-2"
                md="4"
              >
                <b-alert
                  variant="warning"
                  show
                >
                  <h4 class="alert-heading text-center">
                    Previsão de término: {{ horaTerminoPrevista }}
                  </h4>
                </b-alert>
              </b-col>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mt-1">
                  Selecione tipo(s) de campanha
                </h5>
              </b-col>
              <b-col
                md="2"
                class="mb-1"
              >
                <!-- button -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  :variant="variantTipo('ativo')"
                  @click="tipoCampanha('ativo')"
                >
                  Ativo
                </b-button>
              </b-col>
              <b-col
                md="2"
                class="mb-1"
              >
                <!-- button -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  :variant="variantTipo('sms')"
                  @click="tipoCampanha('sms')"
                >
                  SMS
                </b-button>
              </b-col>
              <b-col
                md="2"
                class="mb-1"
              >

                <!-- button -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  :variant="variantTipo('email')"
                  @click="tipoCampanha('email')"
                >
                  E-mail
                </b-button>
              </b-col>
              <b-col
                md="2"
                class="mb-1"
              >

                <!-- button -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  :variant="variantTipo('manual')"
                  @click="tipoCampanha('manual')"
                >
                  Manual
                </b-button>
              </b-col>
              <b-col
                md="2"
                class="mb-1"
              >

                <!-- button -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  :variant="variantTipo('whatsapp')"
                  @click="tipoCampanha('whatsapp')"
                >
                  Whatsapp
                </b-button>
              </b-col>
              <b-col
                md="12"
              >
                <template-sms
                  v-show="tipo === 'sms'"
                />
              </b-col>
              <b-col
                md="12"
              >
                <template-email
                  v-show="tipo === 'email'"
                />
              </b-col>
              <b-col
                md="12"
              >
                <template-servico
                  v-show="tipo === 'ativo'"
                />
              </b-col>
              <b-col
                md="12"
              >
                <template-whatsapp
                  v-show="tipo === 'whatsapp'"
                />
              </b-col>
              <b-col
                md="12"
              >
                <template-manual
                  v-show="tipo === 'manual'"
                />
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

      </form-wizard>
    </b-overlay>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BSpinner,
  BOverlay,
  BFormInvalidFeedback,
  BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { codeIcon } from './code'
import axios from '../../../../../axios-auth'
import Operacoes from './OperacoesFilter.vue'
import Totais from './ResultadoFiltro.vue'
import TemplateSms from './TemplateSms.vue'
import TemplateEmail from './TemplateEmail.vue'
import TemplateServico from './TemplateServico.vue'
import templateWhatsapp from './TemplateWhatsapp.vue'
import TemplateManual from './TemplateManual.vue'

export default {
  components: {
    Operacoes,
    Totais,
    TemplateSms,
    TemplateEmail,
    TemplateServico,
    templateWhatsapp,
    TemplateManual,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    BFormInvalidFeedback,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    hide: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showTotais: false,
      totalClientes: null,
      load: false,
      totalTelefones: null,
      totalOperacoes: null,
      totalEmails: null,
      valorNominal: null,
      campanhaDataInicio: null,
      campanhaDataFinal: null,
      horaInicio: null,
      horaFinal: null,
      respiro: null,
      numerosPorDisparo: null,
      intervaloEnvio: null,
      totais: [],
      dadosTemplateSms: [],
      base: null,
      tipo: null,
      checked: true,
      indeterminate: false,
      value: '',
      context: {
        locale: 'en',
        isRTL: false,
        hourCycle: 'h12',
        hour12: true,
        hours: 12,
        minutes: 0,
        seconds: 0,
        value: '12:00:00',
        formatted: '12:00 PM',
      },
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
    }
  },
  computed: {
    validation() {
      const value = this.intervaloEnvio
      if (value >= 10) {
        return true
      }
      return false
    },
    duracaoTotal() {
      if (this.intervaloEnvio > 0 && this.numerosPorDisparo !== null) {
        const inicio = new Date(`${this.campanhaDataInicio} ${this.horaInicio}`)
        const intervalo = this.intervaloEnvio * 60 * 1000
        const totalEnvios = Math.ceil(this.totalTelefones / this.numerosPorDisparo)

        let horarioAtual = inicio
        let enviosFeitos = 0

        const isHorarioComercial = date => {
          const hours = date.getHours()
          const day = date.getDay()
          return hours >= 8 && hours <= 18 && day !== 0 && day !== 6
        }

        while (enviosFeitos < totalEnvios) {
          if (isHorarioComercial(horarioAtual)) {
            enviosFeitos++
          }

          horarioAtual.setTime(horarioAtual.getTime() + intervalo)

          if (horarioAtual.getHours() >= 18 || horarioAtual.getDay() === 0) {
            const proximoDiaUtil = new Date(horarioAtual.getTime() + 24 * 60 * 60 * 1000)
            proximoDiaUtil.setHours(8, 0, 0, 0)
            horarioAtual = proximoDiaUtil
          }
        }
        return horarioAtual
      }
      return 'Preencha os campos para calcular'
    },
    horaTerminoPrevista() {
      return this.duracaoTotal.toLocaleString('pt-BR')
    },
  },
  methods: {
    variantBase(base) {
      if (base === this.base) {
        return 'primary'
      }
      return 'outline-primary'
    },
    tipoCampanha(tipo) {
      if (tipo !== 'sms') {
        if (localStorage.getItem('templateSms')) {
          localStorage.removeItem('templateSms')
        }
      }
      this.tipo = tipo
    },
    variantTipo(tipo) {
      if (tipo === this.tipo) {
        return 'primary'
      }
      return 'outline-primary'
    },
    executarFiltro() {
      const body = JSON.parse(localStorage.getItem('filtrosCampanha'))
      axios.post('api/v1/operacoes/filtro', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
    },
    toggleIndeterminate(base) {
      this.base = base
      this.indeterminate = !this.indeterminate
    },
    formSubmitted() {
      this.load = true
      const filtroCampanha = JSON.parse(localStorage.getItem('filtrosCampanha'))
      const ttt = filtroCampanha.filtros
      if (ttt.periodo_contato) {
        ttt.periodo_contato.exceto = 1
      }
      const bodyCampanha = {
        tipo_filtro: this.base,
        tipo_campanha: this.tipo,
        data_inicio_campanha: `${this.campanhaDataInicio} ${this.horaInicio}`,
        data_final_campanha: `${this.campanhaDataFinal} ${this.horaFinal}`,
        respiro: 0,
        filtro: ttt,
        usuario_empresas_id: 1,
      }
      if (localStorage.getItem('templateSms')) {
        bodyCampanha.template_id = parseInt(localStorage.getItem('templateSms'))
      }
      if (localStorage.getItem('templateEmail')) {
        bodyCampanha.tempate_email_id = parseInt(localStorage.getItem('templateEmail'))
      }
      if (localStorage.getItem('templateDiscador')) {
        bodyCampanha.servico = localStorage.getItem('templateDiscador')
      }
      if (localStorage.getItem('templateWhatsapp')) {
        bodyCampanha.template_whatsapp_id = parseInt(localStorage.getItem('templateWhatsapp'))
        bodyCampanha.numeros_por_disparo = this.numerosPorDisparo
        bodyCampanha.intervalo_envio = this.intervaloEnvio
        bodyCampanha.ativa = true
      }
      if (localStorage.getItem('templateManual')) {
        bodyCampanha.executivos = JSON.parse(localStorage.getItem('templateManual'))
      }
      axios.post('/api/v1/campanha/store', bodyCampanha, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        if (localStorage.getItem('templateSms')) {
          localStorage.removeItem('templateSms')
        }
        if (localStorage.getItem('templateEmail')) {
          localStorage.removeItem('templateEmail')
        }
        if (localStorage.getItem('templateDiscador')) {
          localStorage.removeItem('templateDiscador')
        }
        if (localStorage.getItem('templateWhatsapp')) {
          localStorage.removeItem('templateWhatsapp')
        }
        if (localStorage.getItem('templateManual')) {
          localStorage.removeItem('templateManual')
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campanha gerada com sucesso!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        document.getElementById('fechar-modal').click()
        this.load = false
      })
        .catch(error => {
          this.$swal({
            title: `Ops, algo deu errado: ${error.data.response.mensagem}`,
            icon: 'danger',
            timer: 1500,
            showConfirmButton: false,
          })
        })
    },
    validationForm() {
      if (this.base) {
        return new Promise((resolve, reject) => {
          this.$refs.accountRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      }
      return this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Selecione alguma opção para continuar',
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
    },
    validationFormInfo() {
      document.getElementById('btGerarFiltro').click()
      setTimeout(() => {
        const body = JSON.parse(localStorage.getItem('filtrosCampanha'))
        body.filtros.tipo_base = this.base

        if (body.filtros.periodo_contato) {
          body.filtros.periodo_contato.exceto = 1
        }
        axios.post('/api/v1/campanha/totalizadores', body, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => {
          const valor = parseFloat(res.data.dados[0].valor_nominal).toFixed(2)
          const valor2 = parseFloat(valor)
          const valor3 = valor2.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
          const Total = [
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: res.data.dados[0].clientes,
              subtitle: 'Clientes',
              customClass: 'mb-2 mb-sm-0',
            },
            {
              icon: 'PhoneIcon',
              color: 'light-primary',
              title: res.data.dados[0].telefones,
              subtitle: 'Telefones',
              customClass: 'mb-2 mb-sm-0',
            },
            {
              icon: 'MailIcon',
              color: 'light-secondary',
              title: res.data.dados[0].emails,
              subtitle: 'Emails',
              customClass: '',
            },
            {
              icon: 'LayersIcon',
              color: 'light-info',
              title: res.data.dados[0].operacoes,
              subtitle: 'Operações',
              customClass: '',
            },
            {
              icon: 'DollarSignIcon',
              color: 'light-success',
              title: valor3,
              subtitle: 'Valor',
              customClass: '',
            },
          ]
          this.totais = Total
          this.showTotais = true
          this.totalClientes = res.data.dados.clientes
          this.totalEmails = res.data.dados.emails
          this.totalTelefones = res.data.dados[0].telefones
          this.totalOperacoes = res.data.dados.operacoes
        })
      }, 300)
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
